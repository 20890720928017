import { requestHandler } from '../../api';

export const fetchSuggestedCompanyConcilations = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/company/suggestedDocumentsConciliation`,
    dispatch,
  });
  if (response) return response.data;
};

export const reverseDocumentConciliation = async (
  dispatch: any,
  payInIdConciliation: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/conciliation/payInConciliation/${payInIdConciliation}`,
    dispatch,
  });
  if (response) return response.data;
};

export const checkReverseConciliation = async (
  dispatch: any,
  payInIdConciliation: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/payInConciliation/${payInIdConciliation}/checkReverse`,
    dispatch,
  });
  if (response) return response.data;
};

export const createCompanyPayIn = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/company/payIn`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const updateCompanyPayIn = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/company/payIn/${data.id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCompanyPayIn = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/company/payIn/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompanyPayIns = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/payIn`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPayInConciliationSuggestions = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/payIn/${data.id}/suggestedConciliation`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentsConciliationSuggestions = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/business/${data.businessIdentifier}/suggestedConciliation`,
    dispatch,
  });
  if (response) return response.data;
};

export const getPayInConciliations = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/payIn/${data.id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createConciliation = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/conciliation/`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createConciliationBatch = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/conciliation/batch`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
