import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { Download, Info, Receipt } from '@mui/icons-material';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { getInvoicingBookReport } from '../../../../../../lib/api/reports';
import ReportInvoicingConfirmDialog from './components/confirmDialog';
import MonthYearSearcher from './components/MonthYearSearcher';
import { Typography } from '@mui/material';
import { getSelectedCertificate } from '../../../../../../lib/api/SIICertificates';
import TheBillerFinalizedModal from '../../../SII/components/TheBiller/components/finalizedModal';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import { CustomTooltip } from '../../../../../../lib/designSystem/TooltipTable';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';

dayjs.locale('es');

const InvoicingBook: React.FC = () => {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState<any>([]); // eslint-disable-line
  const [selectedCertificate, setSelectedCertificate] = useState<any>(null); // eslint-disable-line
  const [selected, setSelected] = useState([]); // eslint-disable-line
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [year, setYear] = useState(dayjs().year());
  const [month, setMonth] = useState(dayjs().month());
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [loading, setLoading] = useState(true);
  const [successInvoices, setSuccessInvoices] = useState<any>([]);
  const [resultInvoices, setResultInvoices] = useState<any>([]);
  const [finalizedModal, setFinalizedModal] = useState(false);
  const [ failed, setFailed ] = useState('' as any); // eslint-disable-line
  const dispatch = useDispatch();

  const handleClose = () => {
    setFinalizedModal(false);
  };

  const exportToExcel = async () => {
    const selectedInvoices = data.filter((item: any) =>
      selectedIds.includes(item.id),
    );
    const plainData: any = [];
    selectedInvoices.forEach((item: any) => {
      plainData.push([
        'FACTURA',
        item.businessIdentifierRv,
        item.businessIdentifierDv,
        item.businessName,
        item.index,
        item.invoicingDate,
        item.invoicingDate,
        item.totalCosts,
        item.totalCostsIva,
        item.totalCostsWithIva,
        '1',
        item.commune,
        item.address,
        item.email,
        'Sin informacion',
      ]);
      plainData.push([
        'DETALLE',
        item.index,
        'Servicios Factoring',
        '1',
        '1',
        item.totalCosts,
        item.totalCosts,
      ]);
    });
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add data to worksheet
    [...plainData].forEach((rowData) => {
      console.log(rowData);
      worksheet.addRow(rowData);
    });
    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();

    URL.revokeObjectURL(url);
  };

  const setUp = async () => {
    setLoading(true);
    const start = dayjs(new Date(year, month, 1)).startOf('month');
    const end = dayjs(new Date(year, month, 1)).endOf('month');
    setStartDate(start);
    setEndDate(end);
    try {
      const response = await getInvoicingBookReport(
        dispatch,
        start.toString(),
        end.toString(),
      );
      const responseWithId = response.map((item: any, index: number) => ({
        ...item,
        id: index,
      }));

      const certificateResponse = await getSelectedCertificate(dispatch);
      setSelectedCertificate(certificateResponse);
      setData(responseWithId);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const parseComissionsToInvoices = (preInvoices: any) => {
    const invoices = preInvoices.map((invoice: any, index: number) => {
      const dateYYYYMMDD = endDate.format('YYYY-MM-DD');
      return {
        index: index + 1,
        identifier: invoice.businessIdentifier,
        name: invoice.businessName,
        issuedDate: dateYYYYMMDD,
        amount: invoice.totalCosts,
        amountWithIva: invoice.totalCostsWithIva,
        commune: invoice.commune,
        address: invoice.address,
        email: invoice.email,
        details: [
          {
            subject: 'Servicios Factoring',
            qty: 1,
            amount: invoice.totalCosts,
          },
        ],
      };
    });
    return invoices;
  };

  const openConfirmDialog = () => {
    if (!selectedCertificate) {
      alert(
        'No tienes un certificado seleccionado. Para utilizar esta función, anda a configuración y selecciona un certificado.',
      );
      return;
    }
    const selectedInvoices = data.filter((item: any) =>
      selectedIds.includes(item.id),
    );
    const formattedInvoices = parseComissionsToInvoices(selectedInvoices);
    setSelected(formattedInvoices);
    setOpenInvoiceDialog(true);
  };

  useEffect(() => {
    setUp();
  }, []);

  const customColumnComponent = (allBusinessCosts: any) => {
    console.log(allBusinessCosts);
    if (!allBusinessCosts) return null;
    const customTitle = (
      <div style={{ padding: '10px' }}>
        <Typography variant="body2" color="textSecondary">
          Desglose costos
        </Typography>
        <EnhancedTable
          dataHeaders={[
            { key: 'requestId', label: 'Id' },
            { key: 'requestType', label: 'Tipo' },
            {
              key: 'operationCostTotal',
              label: 'Costo operación',
              type: 'money',
            },
            {
              key: 'operationCostTotalIva',
              label: 'IVa',
              type: 'money',
            },
            {
              key: 'operationCostTotalWithIva',
              label: 'Total',
              type: 'money',
            },
            {
              key: 'financingAt',
              label: 'Contabilizado en',
              type: 'date',
            },
          ]}
          data={allBusinessCosts}
          rowsPerPageDefault={100}
        />
      </div>
    );
    return (
      <LightTooltip title={customTitle} placement="right">
        <div style={{ cursor: 'pointer' }}>
          <Info />
        </div>
      </LightTooltip>
    );
  };

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        customTitle={
          <MonthYearSearcher
            year={year}
            setYear={setYear}
            month={month}
            setMonth={setMonth}
            setUp={setUp}
          />
        }
        variant="body1"
        buttons={[
          {
            action: () => openConfirmDialog(),
            name: 'Facturar',
            color: 'primary',
            disabled: selectedIds.length === 0,
            startIcon: <Receipt />,
          },
          {
            action: () => exportToExcel(),
            name: 'Descargar XSLX',
            color: 'primary',
            disabled: selectedIds.length === 0,
            startIcon: <Download />,
          },
        ]}
      />
      <Typography variant="body2" color="textSecondary">
        ** Monto emitido corresponde al monto ya emitido del 5 de este mes al 5
        del mes siguiente. Si no estás enrolado, no aparecerá información.
      </Typography>
      <EnhancedTable
        dataHeaders={[
          {
            key: 'allBusinessCosts',
            label: 'Desglose',
            customComponent: customColumnComponent,
            type: 'custom',
          },
          { key: 'businessIdentifier', label: 'RUT cliente' },
          { key: 'businessName', label: 'Nombre cliente' },
          { key: 'totalQuoteCost', label: 'Factoring', type: 'money' },
          {
            key: 'totalPendingAccountCosts',
            label: 'Cuentas por cobrar',
            type: 'money',
          },
          { key: 'totalCosts', label: 'Total neto', type: 'money' },
          { key: 'totalCostsIva', label: 'Total Iva', type: 'money' },
          {
            key: 'totalCostsWithIva',
            label: 'Total factura',
            type: 'money',
          },
          {
            key: 'emittedAmount',
            label: 'Monto emitido **',
            type: 'money',
          },
        ]}
        data={data}
        actions={[]}
        showActions
        rowsPerPageDefault={100}
        selected={selectedIds}
        setSelected={setSelectedIds}
        canSelectAll
        finalSumRow={true}
      />
      <ReportInvoicingConfirmDialog
        confirmOpen={openInvoiceDialog}
        setConfirmOpen={setOpenInvoiceDialog}
        selectedInvoices={selected}
        setSelectedInvoices={setSelected}
        startDate={startDate}
        setUp={setUp}
        endDate={endDate}
        setSuccessInvoices={setSuccessInvoices}
        setResultInvoices={setResultInvoices}
        selectedCertificate={selectedCertificate}
        setFinalizedModal={setFinalizedModal}
        setFailed={setFailed}
      />
      {finalizedModal && (
        <TheBillerFinalizedModal
          finalizedModal={finalizedModal}
          setFinalizedModal={setFinalizedModal}
          resultInvoices={resultInvoices}
          successInvoices={successInvoices}
          handleClose={handleClose}
        />
      )}
      <CustomAlert
        open={failed.length}
        title="Error"
        severity="error"
        text={failed}
        setOpen={setFailed}
      />
    </div>
  );
};

export default InvoicingBook;
