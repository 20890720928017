import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import { getInvoiceNotFinanciable } from '../../../../../../../lib/api/dashboard';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import { validatePermissions } from '../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { getAssignatedExecutives } from '../../../../../../../lib/api/business';

const LastInvoices = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState<any>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [allBusinesses, setAllBusinesses] = useState<any>([]);
  const [allStakeholders, setAllStakeholders] = useState<any>([]);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [selectedStakeholder, setSelectedStakeholder] = useState('');
  const [openExecutive, setOpenExecutive] = useState(false);
  const [executives, setExecutives] = useState([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loadingExecutives, setLoadingExecutives] = useState(false);
  const [selectedBusinessForExecutives, setSelectedBusinessForExecutives] =
    useState('');

  const fetchRelations = async () => {
    setLoading(true);
    const relations = await getInvoiceNotFinanciable(dispatch);
    setInvoices(relations || []);
    setFilteredInvoices(relations || []);

    const businesses = new Set();
    const stakeholders = new Set();

    relations.forEach((invoice: any) => {
      businesses.add(invoice.businessName);
      stakeholders.add(invoice.stakeholderName);
    });

    setAllBusinesses([...businesses]);
    setAllStakeholders([...stakeholders]);

    setLoading(false);
  };

  const handleChange = (event: any, value: any, reason: any, field: any) => {
    if (field === 'business') setSelectedBusiness(value);
    if (field === 'stakeholder') setSelectedStakeholder(value);
    updateFilters();
  };

  const updateFilters = () => {
    let filtered = [...invoices];

    if (selectedBusiness) {
      filtered = filtered.filter(
        (invoice: any) => invoice.businessName === selectedBusiness,
      );
    }
    if (selectedStakeholder) {
      filtered = filtered.filter(
        (invoice: any) => invoice.stakeholderName === selectedStakeholder,
      );
    }

    setFilteredInvoices(filtered);

    const businesses = new Set();
    const stakeholders = new Set();

    filtered.forEach((invoice: any) => {
      businesses.add(invoice.businessName);
      stakeholders.add(invoice.stakeholderName);
    });

    setAllBusinesses([...businesses]);
    setAllStakeholders([...stakeholders]);
  };

  const customTextField = (params: any, label: string) => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        style: {
          padding: '5px 10px', // ajusta el padding del input
        },
      }}
      label={label}
    />
  );

  const businessFilter = () => (
    <Autocomplete
      options={allBusinesses}
      value={selectedBusiness || null}
      getOptionLabel={(option) => option}
      renderInput={(params) => customTextField(params, 'Emisor')}
      onChange={(event, value, reason) =>
        handleChange(event, value, reason, 'business')
      }
      fullWidth
    />
  );

  const stakeholderFilter = () => (
    <Autocomplete
      options={allStakeholders}
      value={selectedStakeholder || null}
      getOptionLabel={(option) => option}
      renderInput={(params) => customTextField(params, 'Receptor')}
      onChange={(event, value, reason) =>
        handleChange(event, value, reason, 'stakeholder')
      }
      fullWidth
    />
  );

  useEffect(() => {
    fetchRelations();
  }, []);

  useEffect(() => {
    updateFilters();
  }, [invoices, selectedBusiness, selectedStakeholder]);

  const fetchExecutives = async (businessIdentifier: string) => {
    setLoadingExecutives(true);
    try {
      const executivesResponse = await getAssignatedExecutives(
        dispatch,
        businessIdentifier,
      );
      setExecutives(executivesResponse);
    } catch (error) {
      console.error(error);
    }
    setLoadingExecutives(false);
  };

  const handleViewExecutive = async (invoiceId: string) => {
    const invoiceSelected = invoices.find(
      (invoice: any) => invoice.id === invoiceId,
    );
    if (invoiceSelected) {
      setOpenExecutive(true);
      setSelectedBusinessForExecutives(invoiceSelected.businessName);
      await fetchExecutives(invoiceSelected.businessIdentifier);
    }
  };

  const canShowExecutives = () => {
    if (validatePermissions(user, 'viewBusinessExecutives')) {
      return true;
    }
    return false;
  };

  const actions = [
    ...(canShowExecutives()
      ? [
          {
            action: handleViewExecutive,
            actionLabel: 'Ver ejecutivos',
          },
        ]
      : []),
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Facturas transables en los útlimos 5 días"
        variant="body1"
        item={
          <Grid container spacing={2} style={{ minWidth: '500px' }}>
            <Grid item xs={6}>
              {businessFilter()}
            </Grid>
            <Grid item xs={6}>
              {stakeholderFilter()}
            </Grid>
          </Grid>
        }
      />

      <EnhancedTable
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Nombre Emisor' },
          { key: 'stakeholderName', label: 'Nombre receptor' },
          { key: 'stakeholderIdentifier', label: 'Rut receptor' },
          { key: 'issuedDate', label: 'Emisión', type: 'date' },
          { key: 'confirmDate', label: 'Confirmación', type: 'date' },
          { key: 'cancelDate', label: 'Reclamo', type: 'date-colored' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'status', label: 'Estado', type: 'cession' },
        ]}
        exportToFile="xlsx"
        data={filteredInvoices}
        actions={actions}
        rowsPerPageDefault={10}
      />
      <Dialog open={openExecutive} onClose={() => setOpenExecutive(false)}>
        <DialogTitle>{selectedBusinessForExecutives}</DialogTitle>
        {loadingExecutives ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <Typography marginBottom={2}>
              Este cliente le pertence a los siguientes ejecutivos:
            </Typography>
            {executives.map((executive: any) => (
              <Typography key={executive.id}>
                - {executive.name} ({executive.email})
              </Typography>
            ))}
            {executives.length === 0 && (
              <Typography>
                No hay ejecutivos asignados a este cliente
              </Typography>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpenExecutive(false)} color="primary">
            Salir
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LastInvoices;
