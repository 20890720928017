import {
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Autocomplete,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import {
  createPaymentRequestPassive,
  getBankAccountsForNominee,
  getPendingAccountsAvailablesForPaymentRequest,
} from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import DocumentsSuggestedContainer from '../../../../../Financing/common/documentSugestion';
import GenerateNominee from '../../../../../Financing/Factoring/components/Recieved/components/ShoppingDialog/components/PaymentModule/components/generateNominee';
import { nomineeHeaders } from '../Invoices';

const pendingAccountsHeaders = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Empresa' },
  { key: 'category', label: 'Categoría' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'observation', label: 'Observación' },
  { key: 'issuedDate', label: 'Fecha', type: 'date' },
];

const documentToApplyHeaders = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Empresa' },
  { key: 'stakeholderName', label: 'Deudor' },
  { key: 'amountToConciliate', label: 'Monto a conciliar', type: 'money' },
];

const PayoutPendingAccounts = (props: any) => {
  const { setOpen, setSuccessOpen, setFailureOpen, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [selectedPendingAccounts, setSelectedPendingAccounts] = useState<any>(
    [],
  );
  const [pendingAccounts, setPendingAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [pendingAccountsConfirmed, setPendingAccountsConfirmed] =
    useState(false);
  const [destinyAccounts, setDestinyAccounts] = useState([]);
  const [originAccounts, setOriginAccounts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [step, setStep] = useState(1);
  const [documentToApplySelected, setDocumentToApplySelected] = useState<any>(
    [],
  );
  const [documents, setDocuments] = useState([]);
  const [amountToApply, setAmountToApply] = useState('');
  const [documentsToApply, setDocumentsToApply] = useState([]);
  const [maxAmount, setMaxAmount] = useState(0);
  const handleAmountToApply = (amount: string) => {
    setAmountToApply(amount);
  };

  const dispatch = useDispatch();

  const setUpDocuments = async () => {
    setLoading(true);
    try {
      const response =
        await getPendingAccountsAvailablesForPaymentRequest(dispatch);
      setPendingAccounts(response);
      setFilteredAccounts(response);
    } catch (error) {
      alert('Error al cargar los documentos');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpDocuments();
  }, []);

  const getStakeholderData = () => {
    if (selectedPendingAccounts.length) {
      const document: any = pendingAccounts.find(
        (invoice: any) => invoice.id === selectedPendingAccounts[0],
      );
      if (document) {
        return {
          businessName: document.businessName,
          businessIdentifier: document.businessIdentifier,
        };
      }
    }
    return { businessName: '', businessIdentifier: '' };
  };

  const { businessIdentifier, businessName } = selectedPendingAccounts.length
    ? getStakeholderData()
    : {
        businessIdentifier: '',
        businessName: '',
      };

  const configurePaymentRequest = () => {
    const pendingAccountsFiltered = pendingAccounts.filter((doc: any) => {
      return selectedPendingAccounts.includes(doc.id);
    });
    const amount = pendingAccountsFiltered.reduce(
      (acc: number, doc: any) => acc + doc.amount,
      0,
    );
    return {
      businessName,
      amount: amount - parseInt(amountToApply),
      documents: pendingAccountsFiltered,
    };
  };

  const validate = (request: any) => {
    if (selectedPendingAccounts.length === 0) {
      setFailureOpen('Debe seleccionar al menos un documento');
      return false;
    }
    console.log(request.amount, amountToApply, parseInt(amountToApply));
    if (request.amount < 0) {
      setFailureOpen(
        'El monto a aplicar es mayor al monto de las cuentas pendientes',
      );
      return false;
    }

    return true;
  };
  const setUpConfigurePayment = async () => {
    const request = configurePaymentRequest();
    if (!validate(request)) {
      return false;
    }
    setLoading(true);
    setSelectedRequest(request);
    if (request.amount === 0) {
      setPendingAccountsConfirmed(true);
      setLoading(false);
      return true;
    }
    try {
      const accounts = await getBankAccountsForNominee(
        dispatch,
        businessIdentifier,
      );
      console.log(accounts);
      if (accounts) {
        setDestinyAccounts(accounts.businessBankAccounts);
        setOriginAccounts(accounts.ownBankAccounts);
        setPendingAccountsConfirmed(true);
        setLoading(false);
        return true;
      }
    } catch (error) {
      setFailureOpen('Error al obtener las cuentas');
      setLoading(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createPaymentRequestPassive(dispatch, {
        payments,
        documentsToApply,
        documentsToPay: selectedPendingAccounts,
      });
      await setUp();
      setSuccessOpen('Pago configurado con éxito');
    } catch (error) {
      setFailureOpen('Error al configurar el pago');
    }
    setLoading(false);
    setOpen(false);
  };

  const stepChecker = async () => {
    switch (step) {
      case 1: {
        const canPass = await setUpConfigurePayment();
        if (!canPass) return;
        return setStep(2);
      }
      case 2:
        return await handleSubmit();
    }
  };

  const disabledCheck = () => {
    if (selectedRequest && selectedRequest.amount === 0) return false;

    if (selectedPendingAccounts.length === 0) return true;

    if (payments.length === 0 && pendingAccountsConfirmed) return true;

    return false;
  };

  const cantSelectRow = (row: any) => {
    if (businessIdentifier && row.businessIdentifier !== businessIdentifier)
      return true;
    return false;
  };

  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return setFilteredAccounts(pendingAccounts);
    console.log(newValue);
    const filtered = pendingAccounts.filter((x: any) => x.id === newValue.id);
    setFilteredAccounts(filtered);
  };

  const autoCompleteFilter = (
    <Autocomplete
      options={filteredAccounts.length ? filteredAccounts : pendingAccounts}
      getOptionLabel={(option: any) =>
        `${option.businessName} - ${option.businessIdentifier}`
      }
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  useEffect(() => {
    if (selectedPendingAccounts.length) {
      const max = selectedPendingAccounts.reduce((acc: any, doc: any) => {
        const document: any = pendingAccounts.find((x: any) => x.id === doc);
        if (!document) return acc;
        return acc + document?.amount;
      }, 0);
      setMaxAmount(max);
    }
  }, [selectedPendingAccounts]);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <DialogContent style={{ padding: '0px 24px' }}>
        {step === 1 && (
          <div>
            <HeaderWithActions
              title="Seleccione las cuentas pendientes a pagar"
              variant="body1"
              type="subHeader"
              item={autoCompleteFilter}
            />
            <EnhancedTable
              data={filteredAccounts}
              dataHeaders={pendingAccountsHeaders}
              selected={selectedPendingAccounts}
              setSelected={setSelectedPendingAccounts}
              rowsPerPageDefault={5}
              cantSelectRow={cantSelectRow}
            />
            <Grid container alignItems={'center'} padding={'8px 0px'}>
              <Grid item xs={8}>
                <Typography variant="body1" margin={'8px'}>
                  Documentos aplicados:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <DocumentsSuggestedContainer
                  businessIdentifier={businessIdentifier}
                  amountToApply={amountToApply}
                  setAmountToApply={handleAmountToApply}
                  selected={documentToApplySelected}
                  setSelected={setDocumentToApplySelected}
                  documents={documents}
                  maxAmount={maxAmount}
                  setDocuments={setDocuments}
                  setDocumentsToApply={setDocumentsToApply}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {step === 2 && !payments.length ? (
          <div>
            {selectedRequest.amount === 0 ? (
              <div>
                <Typography variant="body1">
                  Debido a que el monto a aplicar es igual al monto de las
                  cuentas pendientes, no es necesario realizar un pago. Presione
                  finalizar y quedarán cuadradas las cuentas a pagar con las
                  cuentas pendientes.
                </Typography>
                <div style={{ padding: '12px 0px' }}>
                  <Typography variant="body1" margin={'4px'}>
                    Documentos por pagar
                  </Typography>
                  <EnhancedTable
                    dataHeaders={pendingAccountsHeaders}
                    data={selectedRequest.documents}
                  />
                </div>
                <div style={{ padding: '12px 0px' }}>
                  <Typography variant="body1" margin={'4px'}>
                    Documentos aplicados
                  </Typography>
                  <EnhancedTable
                    dataHeaders={documentToApplyHeaders}
                    data={documentsToApply}
                  />
                </div>
              </div>
            ) : (
              <GenerateNominee
                availableAccounts={destinyAccounts}
                originAccounts={originAccounts}
                selectedRequest={selectedRequest}
                setPayments={setPayments}
                amount={selectedRequest.amount}
              />
            )}
          </div>
        ) : null}
        {step === 2 && payments.length ? (
          <div>
            <Typography variant="body1" margin={'4px'}>
              Seleccione la configuración de pago
            </Typography>
            <EnhancedTable dataHeaders={nomineeHeaders} data={payments} />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => (step === 1 ? setOpen(false) : setStep(step - 1))}
        >
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => stepChecker()}
          disabled={disabledCheck()}
        >
          {' '}
          {step === 1 ? 'Siguiente' : 'Finalizar'}{' '}
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default PayoutPendingAccounts;
