import { Grid, Typography } from '@mui/material';
import React from 'react';
import TextField from '@mui/material/TextField';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import DocumentsSuggestedContainer from '../../../documentSugestion';

const FinanceHubForm = (props: any) => {
  const {
    liveForm,
    setFormData,
    formData,
    selectedRequest,
    updateSimulationValues,
    setDocumentsToApply,
    subProduct,
    handleChange,
    documents,
    selected,
    setSelected,
    setDocuments,
    onEditing,
    documentsToApply,
  } = props;

  const gridStyle = {
    alignContent: 'center',
    alignItems: 'center',
    padding: '4px',
    paddingRight: 20,
  };

  const handleAmountToApply = (value: string) => {
    const newValue = Number(value.replace('$', '').replaceAll('.', ''));
    setFormData({
      ...formData,
      amountToApply: newValue,
    });
    updateSimulationValues({
      actualForm: { ...formData, amountToApply: newValue },
    });
  };

  console.log('liveForm', liveForm);

  const maxAmount =
    liveForm.operationAdvanced -
    liveForm.operationCostComissionVariable -
    liveForm.operationCostComissionFixed -
    liveForm.expensesCost -
    liveForm.operationCostRate -
    liveForm.operationIva;
  console.log('maxAmount', maxAmount);

  return (
    <div
      style={{
        padding: '0px 0px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Typography variant="h6">
        <b>Producto:</b>{' '}
        {subProduct ? subProduct.name : selectedRequest.subProductName}
      </Typography>
      <Typography variant="body1" fontWeight={600}>
        Montos
      </Typography>
      <Grid container style={gridStyle} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1">Anticipo (%) </Typography>

          <TextField
            value={formData.advance}
            placeholder="100"
            type="number"
            disabled={!onEditing}
            fullWidth
            style={{ padding: '0px !important' }}
            onChange={(e) => handleChange('advance', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Documentos a aplicar ($)</Typography>
          <DocumentsSuggestedContainer
            businessIdentifier={selectedRequest.businessIdentifier}
            setAmountToApply={handleAmountToApply}
            amountToApply={formData.amountToApply}
            maxAmount={maxAmount}
            selected={selected}
            setSelected={setSelected}
            documents={documents}
            setDocuments={setDocuments}
            setDocumentsToApply={setDocumentsToApply}
            addIconPlacement="end"
            disabled={!onEditing}
            documentsToApply={documentsToApply}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" marginTop={1} fontWeight={600}>
        Tasas
      </Typography>
      <Grid container style={gridStyle} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1">Interés (%)</Typography>
          <TextField
            value={formData.rate}
            type="number"
            fullWidth
            disabled={!onEditing}
            placeholder="2.2"
            onChange={(e) => handleChange('rate', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Interés mora (%)</Typography>
          <TextField
            value={formData.arrearRate}
            type="number"
            fullWidth
            disabled={!onEditing}
            placeholder="2.2"
            onChange={(e) => handleChange('arrearRate', e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container style={gridStyle} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1" marginTop={1} fontWeight={600}>
            Costo de operación
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Typography variant="body1">Fijo ($)</Typography>
              <TextField
                value={formatMoney(formData.operationCostComissionFixed)}
                type="text"
                disabled={!onEditing}
                fullWidth
                onChange={(e) =>
                  handleChange('operationCostComissionFixed', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1">Variable (%)</Typography>
              <TextField
                value={formData.operationCostComissionVariablePercentage}
                type="number"
                fullWidth
                disabled={!onEditing}
                onChange={(e) =>
                  handleChange('operationCostComissionVariable', e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" marginTop={1} fontWeight={600}>
            Otros gastos
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">Fijo ($)</Typography>
              <TextField
                value={formatMoney(formData.expensesCost)}
                type="text"
                disabled={!onEditing}
                fullWidth
                onChange={(e) => handleChange('expensesCost', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FinanceHubForm;
