import React, { useEffect, useState } from 'react';
import { getDocumentsConciliationSuggestions } from '../../../../../../lib/api/conciliations';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  Fade,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Cancel } from '@mui/icons-material';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { formatMoney } from '../../../../../../lib/functions/utils';

const DocumentsSuggestedContainer = (props: any) => {
  const {
    businessIdentifier,
    amountToApply,
    setAmountToApply,
    selected,
    setSelected,
    maxAmount,
    documents,
    setDocuments,
    setDocumentsToApply,
    addIconPlacement,
    disabled,
    documentsToApply,
  } = props;
  props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [openPopper, setOpenPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>('right');
  const [documentsTypes, setDocumentsTypes] = useState<any[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<any[]>([]);
  const [filterType, setFilterType] = useState<any>(null);

  const handleClickPopper =
    (newPlacement: PopperPlacementType) =>
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      console.log('click', placement, newPlacement);
      setAnchorEl(event.currentTarget);
      setOpenPopper((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
      await setUp();
    };

  const handleClose = () => {
    setOpenPopper(false);
    setFilterType(null);
  };
  const handleChangeAmountToConciliate = (event: any) => {
    console.log('event', event.target.value);
    const amount = event.target.value.split('.').join('').split('$').join('');
    console.log('amount', amount);

    if (isNaN(amount)) return;

    const amountToApply = Number(amount);
    const document = documents.find((inv: any) => inv.id === event.target.name);
    if (
      amountToApply < 0 ||
      Math.ceil(amountToApply) > Math.ceil(document.totalRemaining)
    ) {
      return alert('El monto a conciliar no puede ser mayor al saldo restante');
    }
    document.amountToConciliate = amountToApply;
    setDocuments([...documents]);
    handleSelectChange(documents);
  };

  const cantSelectRow = (row: any) => {
    console.log('maxAmount', maxAmount);
    if (!maxAmount) return false;
    if (selected.includes(row.id)) return false;
    console.log(
      'row',
      row.amount,
      amountToApply,
      row.amountToConciliate,
      amountToApply + row.amountToConciliate,
      maxAmount,
    );
    return Number(amountToApply) + Number(row.amountToConciliate) > maxAmount;
  };

  const documentsHeaders = [
    { key: 'folio', label: 'Folio' },
    { key: 'type', label: 'Tipo' },
    // { key: 'subType', label: 'Subtipo' },
    { key: 'stakeholderName', label: 'Pagador' },
    { key: 'amountNet', label: 'Monto neto', type: 'money' },
    { key: 'totalRemainingNet', label: 'Saldo', type: 'money' },
    {
      key: 'amountToConciliate',
      label: 'Monto a conciliar',
      handleChange: handleChangeAmountToConciliate,
      type: 'money',
    },
  ];

  const prepareDocuments = (documents: any) => {
    console.log('documents to apply', documentsToApply);
    return documents.map((document: any) => {
      const documentToApply = documentsToApply.find(
        (doc: any) => doc.id === document.id,
      );
      return {
        ...document,
        amountToConciliate: documentToApply
          ? documentToApply.amountToConciliate
          : maxAmount
            ? Math.min(maxAmount, document.totalRemainingNet)
            : document.totalRemainingNet,
      };
    });
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getDocumentsConciliationSuggestions(dispatch, {
        businessIdentifier,
      });
      console.log('response', response);
      const documentsPrepared = prepareDocuments(response.documentsProcessed);
      const types = response.documentsProcessed.map((doc: any) => doc.type);
      setDocumentsTypes([...new Set(types)]);
      setDocuments(documentsPrepared);
      setFilteredDocuments(documentsPrepared);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSelectChange = (newDocuments?: any) => {
    if (!documents.length || (newDocuments && !newDocuments.length)) return;
    const selectedDocuments = (newDocuments || documents).filter((inv: any) =>
      selected.includes(inv.id),
    );
    console.log('selectedDocuments', selectedDocuments);
    const amountToApply = selectedDocuments.reduce(
      (acc: number, inv: any) => acc + inv.amountToConciliate,
      0,
    );
    console.log(
      'amountToApply',
      amountToApply,
      maxAmount,
      amountToApply > maxAmount,
    );
    // if (maxAmount && amountToApply > maxAmount) {
    //   alert('El monto a conciliar no puede ser mayor al monto a depositar');
    //   return;
    // }
    setAmountToApply(String(amountToApply.toFixed(0)));
    setDocumentsToApply(selectedDocuments);
  };

  const handleClickFilter = (type?: string) => {
    console.log('type', type, filterType);
    setFilterType(type); // This will re-render the component with the new filterType
    if (!type) {
      setFilteredDocuments(documents); // Show all documents if no type is specified
    } else {
      const filtered = documents.filter((doc: any) => doc.type === type);
      setFilteredDocuments(filtered); // Show filtered documents
    }
  };

  useEffect(() => {
    handleSelectChange();
  }, [selected]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {addIconPlacement === 'end' && (
          <TextField
            value={formatMoney(amountToApply)}
            fullWidth
            placeholder="0"
            disabled
          />
        )}
        <IconButton
          onClick={handleClickPopper(
            // addIconPlacement === 'end' ? 'right' : 'left-end',
            'right',
          )}
          disabled={!businessIdentifier || disabled}
        >
          <Add />
          {selected.length !== 0 ? (
            <Typography
              style={{
                position: 'absolute',
                bottom: -5,
                right: 0,
                color: 'white',
                fontSize: 10,
                padding: '2px 6px',
                borderRadius: 50,
                fontWeight: '400',
                margin: '1px 2px',
                backgroundColor: '#297dff',
              }}
            >
              {selected.length}
            </Typography>
          ) : null}
        </IconButton>
        {addIconPlacement !== 'end' && (
          <TextField
            value={formatMoney(amountToApply)}
            fullWidth
            placeholder="0"
            disabled
          />
        )}
      </div>
      <Popper
        sx={{ zIndex: 1300 }}
        open={openPopper}
        anchorEl={anchorEl}
        placement={'bottom'}
        transition={false}
        modifiers={[
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <Paper
          key={filteredDocuments.length}
          style={{
            padding: 20,
            maxWidth: 800,
            maxHeight: 400,
            overflow: 'scroll',
          }}
        >
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <Fade in={loading}>
                <CircularProgress />
              </Fade>
            </div>
          ) : (
            <div>
              {' '}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="h6">Documentos</Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      onClick={() => handleClickFilter()}
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontWeight: !filterType ? 600 : 200,
                      }}
                    >
                      Todos
                    </Typography>
                    {documentsTypes.map((type: any) => (
                      <div key={type}>
                        <Typography onClick={() => handleClickFilter(type)}>
                          |{' '}
                          <span
                            style={{
                              cursor: 'pointer',
                              marginRight: 10,
                              fontWeight: filterType === type ? 600 : 200,
                            }}
                          >
                            {type}
                          </span>
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <IconButton onClick={() => handleClose()}>
                    <Cancel />
                  </IconButton>
                </div>
              </div>
              <EnhancedTable
                dataHeaders={documentsHeaders}
                data={filteredDocuments}
                selected={selected}
                setSelected={setSelected}
                cantSelectRow={cantSelectRow}
              />
            </div>
          )}
        </Paper>
      </Popper>
    </div>
  );
};

export default DocumentsSuggestedContainer;
