// 1_ShoppingCartFactoringCreated.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import Parameters from '../../../../../../../../../common/parameters';
import SimulationPreview from '../../../../../../../../../common/SimulationPreview';
import DocumentsPreview from '../../../../../../../../../common/documentsPreview';
import { decrementPendingRequests } from '../../../../../../../../../../../../../services/redux/notificactionSlice';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';
import {
  calculateRequestDetails,
  validateCompanyTypes,
} from '../../../../../../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../../../../../../../../../lib/designSystem/Alert';
import {
  createFactoringRequest,
  editParamsOnReview,
} from '../../../../../../../../../../../../../lib/api/factoring';
import { submitFactoringQuoteQuotation } from '../../../../../../../../../../../../../lib/api/factoring/updateStatus';
import DialogActionsWrapper from '../DialogActionWrapper';

const ShoppingCartFactoringCreated = (props: any) => {
  const {
    setOpenShoppingCart,
    selectedRequest,
    setSelectedRequest,
    setSuccessOpen,
    setFailureOpen,
    setUp,
    setLoading,
    executiveCreating,
    businessId,
    onlySimulation,
    editing,
    setOpenDatesSelection,
    setBackwards,
    subProduct,
    setFormData,
    formData,
    handleOpenDeleteConfirmModal,
  } = props;
  const { id } = useParams<{ id: string }>();
  const [onEditing, setOnEditing] = useState(true);
  const [alertText, setAlertText] = useState('');
  const [showForm, setShowForm] = useState<any>({
    advance: true,
    rate: true,
    operationCost: true,
  });
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [documentsToApply, setDocumentsToApply] = useState<any[]>([]);
  const [finalOperationForm, setFinalOperationForm] = useState<any>({
    operationAmount: null,
    operationAdvanced: null,
    operationCostRate: null,
    operationCostComissionFixed: null,
    operationCostComissionVariable: null,
    expensesCost: null,
    operationCostIva: null,
    operationAmountToApply: null,
    operationToDepost: null,
    advance: null,
    rate: null,
  });
  const dispatch = useDispatch();

  const startingDocumentSelected = selectedRequest.documents
    .filter((document: any) => document.active)
    .map((document: any) => document.id);

  const [selected, setSelected] = React.useState<readonly string[]>(
    startingDocumentSelected,
  );

  const handleSubmitQuotation = async () => {
    if (!validateParams()) return alert('Por favor completa todos los campos');
    setLoading(true);
    try {
      await submitFactoringQuoteQuotation(dispatch, {
        advance: Number(formData.advance),
        rate: Number(formData.rate),
        arrearRate: Number(formData.arrearRate),
        amountToApply: formData.amountToApply,
        operationCostFixed: formData.operationCostComissionFixed,
        operationCostVariable: formData.operationCostComissionVariable,
        documentsToApply,
        expensesCost: formData.expensesCost,
        operationCost: formData.operationCost,
        id: selectedRequest.quoteActive.id,
        status: 'QUOTED',
        documents: selectedRequest.documents,
        contactData: formData.contactData,
        onlySimulation,
      });
      dispatch(decrementPendingRequests());
      setSuccessOpen(
        `Se ha enviado la solicitud ${selectedRequest.id} al cliente`,
      );
      await setUp();
    } catch (error) {
      console.log(error);
      setFailureOpen('Lamentablemente ha ocurrido un error');
    }
    setLoading(false);
    setOpenShoppingCart(false);
  };

  const handleEditOnInReview = async () => {
    if (!validateParams()) return;
    setLoading(true);
    try {
      const filteredDocuments = selectedRequest.documents.filter(
        (document: any) => selected.includes(document.id),
      );
      await editParamsOnReview(dispatch, selectedRequest.id, {
        advance: Number(formData.advance),
        rate: Number(formData.rate),
        operationCostFixed: formData.operationCostComissionFixed,
        operationCostVariable: formData.operationCostComissionVariable,
        arrearRate: Number(formData.arrearRate),
        amountToApply: formData.amountToApply,
        expensesCost: formData.expensesCost,
        documentsToApply,
        editedDocumentsToApply: formData.editedDocumentsToApply,
        documents: filteredDocuments,
      });
      await setUp();
      setOpenShoppingCart(false);
      setSuccessOpen(`Se ha actualizado la solicitud ${selectedRequest.id}.`);
    } catch (error) {
      setFailureOpen('Lamentablemente ha ocurrido un error');
    }
    setLoading(false);
  };

  const handleCreateQuote = async (exception = false) => {
    // MEtodo para crear
    if (!validateParams()) return;
    setLoading(true);
    try {
      const quotedData = {
        advance: Number(formData.advance),
        rate: Number(formData.rate),
        operationCostFixed: formData.operationCostComissionFixed,
        operationCostVariable: formData.operationCostComissionVariable,
        arrearRate: Number(formData.arrearRate),
        expensesCost: formData.expensesCost,
        amountToApply: formData.amountToApply,
      };

      const filteredDocuments = selectedRequest.documents.filter(
        (document: any) => selected.includes(document.id),
      );

      const payload = {
        executives: [user?.id],
        requestsDocuments: filteredDocuments,
        businessIdentifier: id || businessId,
        documentsToApply,
        subProductId: selectedRequest.subProduct || 'electronic-invoice',
        quotedData,
        userEmail: formData.email,
        showForm,
        exception,
        contactData: formData.contactData,
        useCase: onlySimulation ? 'quotation' : 'financing-hub',
      };

      await createFactoringRequest(dispatch, payload);
      setSuccessOpen(`Se ha enviado la solicitud a evaluación.`);
      setOpenShoppingCart(false);
      if (businessId) {
        await setUp();
      }
    } catch (error: any) {
      console.log(error);
      alert('Lamentablemente ha ocurrido un error');
    }
    setLoading(false);
  };

  const handleFoward = async (exception = false) => {
    if (executiveCreating) {
      return await handleCreateQuote(exception);
    } else {
      return await handleSubmitQuotation();
    }
  };

  const checkValidEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateParams = () => {
    if (
      !finalOperationForm.operationAmount ||
      !finalOperationForm.operationAdvanced ||
      !formData.advance ||
      !formData.rate
    ) {
      alert('Por favor completa todos los campos');
      return false;
    }
    if (finalOperationForm.operationCostRate <= 0) {
      alert('El costo del interés no puede ser 0');
      return false;
    }
    if (finalOperationForm.operationCostRate <= 0) {
      alert('El costo del interés no puede ser 0');
      return false;
    }

    if (onlySimulation && !selectedRequest.userOwner) {
      if (!formData.contactData) {
        alert('Por favor agregar un contacto para enviar la simulación');
        return false;
      }
      if (formData.contactData.id) {
        return true;
      }
      if (!checkValidEmail(formData.contactData.email)) {
        alert('Por favor agregar un email válido');
        return false;
      }
    }
    if (validateCompanyTypes(user, 'FinancingHub')) {
      if (!formData.arrearRate) {
        alert('Por favor completa la tasa de mora');
        return false;
      }
    }
    return true;
  };

  const updateSimulationValues = ({
    actualForm,
    newSelectedRequest,
  }: {
    actualForm: any;
    newSelectedRequest?: any;
  }) => {
    const requestToEvaluate = newSelectedRequest
      ? newSelectedRequest
      : selectedRequest;
    const updatedForm = actualForm ? actualForm : formData;

    requestToEvaluate.advance = updatedForm.advance;
    requestToEvaluate.rate = updatedForm.rate;
    requestToEvaluate.operationCostComissionFixed =
      updatedForm.operationCostComissionFixed;
    requestToEvaluate.operationCostComissionVariable =
      updatedForm.operationCostComissionVariable;
    requestToEvaluate.arrearRate = updatedForm.arrearRate;
    requestToEvaluate.amountToApply = updatedForm.amountToApply;
    requestToEvaluate.expensesCost = updatedForm.expensesCost;
    const {
      operationAmount,
      operationAdvanced,
      operationCostRate,
      operationToDepost,
      operationIva,
      operationCostComissionFixed,
      operationCostComissionVariable,
      advance,
      rate,
      expensesCost,
      documentsWithDetails,
      operationAmountToApply,
    } = calculateRequestDetails(requestToEvaluate);
    requestToEvaluate.documents = documentsWithDetails;
    setSelectedRequest(requestToEvaluate);

    setFinalOperationForm({
      operationAmount,
      operationAdvanced,
      operationCostRate,
      operationToDepost,
      operationIva,
      expensesCost,
      operationAmountToApply,
      operationCostComissionFixed,
      operationCostComissionVariable,
      advance,
      rate,
    });
  };

  const disabledBecasueOfDocuments = () => {
    const filteredDocuments = selectedRequest.documents.filter(
      (document: any) => selected.includes(document.id),
    );
    if (filteredDocuments.length === 0) return true;
    return false;
  };

  const haveToSetup = async () => {
    if (editing) {
      const selectedDocumentsToApply =
        selectedRequest.quoteActive.documentsToApply.map(
          (doc: any) => doc.documentId,
        );
      const previousData = {
        advance: selectedRequest.quoteActive.advance,
        rate: selectedRequest.quoteActive.rate,
        arrearRate: selectedRequest.quoteActive.arrearRate,
        operationCostComissionFixed:
          selectedRequest.quoteActive.operationCostFixed,
        operationCostComissionVariable:
          selectedRequest.quoteActive.operationCostVariable,
        operationCostComissionVariablePercentage: (
          (selectedRequest.quoteActive.operationCostVariable /
            selectedRequest.quoteActive.amount) *
          100
        ).toFixed(2),
        expensesCost: selectedRequest.quoteActive.expensesCost,
        selectedDocumentsToApply,
        amountToApply: selectedRequest.quoteActive.amountToApply,
        contactData: selectedRequest.contactData,
      };
      setOnEditing(false);
      setFormData(previousData);
      updateSimulationValues({ actualForm: previousData });
      setDocumentsToApply(
        selectedRequest.quoteActive.documentsToApply.map((doc: any) => ({
          id: doc.documentId,
          amountToConciliate: doc.amount,
        })),
      );
    }
  };

  const handleBackwards = () => {
    if (executiveCreating) {
      setOpenDatesSelection(true);
      setOpenShoppingCart(false);
      setBackwards(true);
      return;
    }
    setOpenShoppingCart(false);
  };

  useEffect(() => {
    haveToSetup();
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Parameters
            formData={formData}
            setFormData={setFormData}
            selectedRequest={selectedRequest}
            updateSimulationValues={updateSimulationValues}
            showForm={showForm}
            setShowForm={setShowForm}
            executiveCreating={executiveCreating}
            setDocumentsToApply={setDocumentsToApply}
            subProduct={subProduct}
            onlySimulation={onlySimulation}
            onEditing={onEditing}
            liveForm={finalOperationForm}
            documentsToApply={documentsToApply}
          />
        </Grid>
        <Grid item xs={6}>
          <div style={{ padding: '0px 12px' }}>
            <SimulationPreview
              selectedRequest={selectedRequest}
              liveForm={finalOperationForm}
              isLive
            />
          </div>
        </Grid>
      </Grid>
      <DocumentsPreview
        selectedRequest={selectedRequest}
        simulationInProgress
        documentsInSelection={onEditing}
        updateSimulationValues={updateSimulationValues}
        selected={selected}
        setSelected={setSelected}
        setSelectedRequest={setSelectedRequest}
      />
      {editing ? (
        <DialogActionsWrapper
          onClose={() => setOpenShoppingCart(false)}
          handleOpenDeleteConfirmModal={() =>
            handleOpenDeleteConfirmModal(selectedRequest.id)
          }
          actions={
            <>
              {onEditing && (
                <CustomButton
                  onClick={() => setOnEditing(false)}
                  color="secondary"
                >
                  cancelar
                </CustomButton>
              )}
              {onEditing ? (
                <CustomButton
                  onClick={() => handleEditOnInReview()}
                  color="warning"
                  disabled={disabledBecasueOfDocuments()}
                >
                  Guardar
                </CustomButton>
              ) : (
                <CustomButton
                  onClick={() => {
                    setAlertText(
                      'Al guardar, todas las aprobaciones existentes se eliminarán. ',
                    );
                    setOnEditing(true);
                  }}
                  color="primary"
                >
                  Editar
                </CustomButton>
              )}
            </>
          }
        />
      ) : (
        <DialogActionsWrapper
          onClose={handleBackwards}
          handleOpenDeleteConfirmModal={() =>
            handleOpenDeleteConfirmModal(selectedRequest.id)
          }
          actions={
            <>
              {!onlySimulation && (
                <CustomButton
                  onClick={() => handleFoward(true)}
                  color="warning"
                >
                  excepción
                </CustomButton>
              )}
              <CustomButton onClick={() => handleFoward()} color="primary">
                {executiveCreating ? 'Crear' : 'Cotizar'}
              </CustomButton>
            </>
          }
        />
      )}
      <CustomAlert
        setOpen={setAlertText}
        open={alertText.length > 0}
        title="Cuidado"
        text={alertText}
        severity="warning"
      />
    </div>
  );
};

export default ShoppingCartFactoringCreated;
